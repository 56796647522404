jQuery(document).ready( function() {
  
      var $message_wrapper = jQuery('.accessibility-notice-no-js');
      $message_wrapper.remove();
      var $link_wrapper = jQuery('.accessibility-notice-js');
      $link_wrapper.addClass('oit-js-init'); 

      jQuery("#accessibilityModal").on('show.bs.modal', function(){
        jQuery('html').css('overflow', 'hidden');
        jQuery("#accessibilityModal .modal-dialog").css('margin-top', '0');
        jQuery("#accessibilityModal .modal-dialog").css('margin-bottom', '0');
     });

     jQuery("#accessibilityModal").on('hidden.bs.modal', function(){
        jQuery('html').css('overflow', '');
        jQuery("#accessibilityModal .modal-dialog").css('margin-top', '');
        jQuery("#accessibilityModal .modal-dialog").css('margin-bottom', '');
   });
  });
  